import * as React from "react";
import Layout from "../../layouts";
import Button from "@partials/button";
import ImageBlock from "@partials/image-block/image-block";
import "./_gallery_styles.scss";
import FallenStatue from "@images/fallen-statue.png";
import FallenStatueX2 from "@images/fallen-statue-x2.png";
import SweetDream from "@images/sweet-dream.png";
import SweetDreamX2 from "@images/sweet-dream-x2.png";
import RussellDrisch_SSGoodFortune from "@images/galleries/holidays/RussellDrisch_SSGoodFortune.png";
import QuoteSection from "@partials/quote-section/quote-section";
import { quotes } from "../../../pageData";

export default function Gallery() {
    return (
        <Layout pageTitle="Gallery">
            <section className="gallery-hero-section position-relative overflow-hidden">
                <div className="container">
                    <div className="row justify-content-center align-items-center gap-5 gap-lg-0">
                        <div className="col-lg-6">
                            <ImageBlock
                                image={FallenStatue}
                                imageX2={FallenStatueX2}
                                alt="postcard that statue of Liberty fallen into the Hudson River, in the background are WTC Towers and New York panorama"
                                classNames="c-mb-only-5"
                            />
                            <h1 className="c-mb-only-2">Series I</h1>
                            <p className="c-mb-only-4">
                                The initial edition of thirty-nine postcards was the first of its kind to be published in the U.S.A. Distinct from art postcards that are miniature versions of celebrated works, each Artists’ Postcard carries a new picture created especially for it by a living artist.
                            </p>
                            <Button buttonText={"Explore Series I"} link="/gallery/series-I" />
                        </div>
                        <div className="col-lg-6">
                            <ImageBlock
                                image={SweetDream}
                                imageX2={SweetDreamX2}
                                alt="postcard that shows Abraham Lincoln, cuneiform and swimming pool from beginning of the 20th century"
                                classNames="c-mb-only-5"
                            />
                            <h1 className="c-mb-only-2">Series II</h1>
                            <p className="c-mb-only-4">
                                Series II consists of fifty works in varied media, produced originally on more than ten different papers, by diverse printing processes. The artists are painters, sculptors, photographers, poets, cartoonists, writers, filmmakers, architects, and a dancer.
                            </p>
                            <Button buttonText={"Explore Series II"} link="/gallery/series-II" />
                        </div>
                    </div>
                    <div className="row align-items-center gap-5 gap-lg-0 c-mt-7 ">
                        <div className="col-lg-6">
                            <ImageBlock
                                image={RussellDrisch_SSGoodFortune}
                                imageX2={RussellDrisch_SSGoodFortune}
                                alt="small, white boat with christmas trees on the edges"
                                classNames="c-mb-only-5"
                            />
                            <h1 className="c-mb-only-2">Holiday Cards</h1>
                            <p className="c-mb-only-4">
                                Based on the popularity of the Artists’ Postcards Series I and II editions, select artists from the earlier series created special cards for the 1979 holiday season.
                            </p>
                            <Button buttonText={"Explore Holiday Cards"} link="/gallery/holiday-cards" />
                        </div>
                    </div>
                </div>
            </section>
            <QuoteSection data={quotes.galleryPage} />
        </Layout>
    );
}
